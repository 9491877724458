.header{
    width: 100%;
    background-color: brown;
  }

.header-main{
    display: flex;
    padding: 20px;
    justify-content: center;
    &__logo{
        width: 50px;
        height: 50px;
        img {
            width: 50px;
            height: 50px;
        }
    }
}
