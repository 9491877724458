.input-wrapper{
  display: flex;
  flex-direction: column;
  label{
    margin-bottom: 5px;
  }

  margin-bottom: 10px;
  input{
    &.error{
      border: 2px solid crimson;
    }
  }
}
