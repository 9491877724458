.header-sub-wrapper{
  width: 100%;
  button{
    width: 100%;
    border: none;
    padding: 0;
    cursor: pointer;
  }
}

.header-sub{
  background-color: crimson;
  padding: 10px;
  &__stroke{
    display: inline-block;
    white-space: nowrap;
    animation: floatText 15s infinite linear;
    padding-left: 100%;
    width: 100%;
    height: 100%;
    &:hover{
      animation-play-state: paused;
    }
  }

}

@keyframes floatText {
  to {
    transform: translateX(-100%);
  }
}


