.btn{
  color: white;
  border: none;
  cursor: pointer;
}

.disabled-btn{
  cursor: not-allowed;
  opacity: 0.3;
}
